<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('common.top_title', {nickname: myInfo.user_nickname})"></h1>
      <p>{{ $t('common.top_subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="mypage_sub_title">
        <a href="javascript:" @click="this.$router.back()">
          <img src="../../assets/img/btn_back.png">
        </a>{{ $t('transfer.title') }}
      </div>
      <div class="mypage_sub_btn">
        <a href="javascript:" :class="{now: category == 0}" @click="onCategory(0)">
          {{ $t('transfer.point2bread') }}
        </a>
        <a href="javascript:" :class="{now: category == 1}" @click="onCategory(1)">
          {{ $t('transfer.bread2point') }}
        </a>
      </div>
    </div>

    <div class="warp">
      <div class="my_point">
        {{ myInfo.user_nickname }}'s Point & Bread :
        <span>{{ $t('common.point') }}: <b>{{ currency(myInfo.points) }}</b></span>
        <span>{{ $t('common.bread') }}: <b>{{ currency(myInfo.cash) }}</b></span>
      </div>
      <div class="transfer_list">
        <ul>
          <li v-for="(transfer, idx) in transferList[category]" :key="idx" @click="selectTransfer(idx)">
            <a href="javascript:" :class="{now: idx == selectedTransferIdx}">
              <img src="../../assets/img/ico_check.png">
            </a>
            <b>{{ currency(category == 0 ? transfer.point : transfer.bread) }}</b>
            {{ (category == 0 ? $t('common.point') : $t('common.bread')) }} >>
            <span><b>{{ currency(category == 0 ? transfer.bread : transfer.point) }}</b> {{
                (category == 0 ? $t('common.bread') : $t('common.point'))
              }}</span>
          </li>
        </ul>
      </div>
      <div class="transfer_btn">
        <a href="javascript:" @click="onTransfer">{{ $t('transfer.title') }}</a>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./Transfer.scss"></style>

<script>
import { requestPost, showToast } from "../../lib/util";
import { PAGE_SIZE } from "../../lib/constant";
import InfiniteLoading from 'v3-infinite-loading';
import store from "../../store";

export default {
  name: 'History',
  components: {
    InfiniteLoading,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    let myInfo = this.$store.getters.me;
    return {
      myInfo: myInfo,
      category: 0,
      transferList: [],
      selectedTransferIdx: 0,
    }
  },
  mounted() {
    this.initList();
  },
  methods: {
    onCategory: function (_category) {
      this.category = _category;
      this.initList();
    },
    initList: function () {
      this.logList = [];
      this.getList();
    },
    getList: function () {
      this.transferList = [
        [
          { bread: 400, point: 1000 },
          { bread: 1200, point: 3000 },
          { bread: 2000, point: 5000 },
          { bread: 4000, point: 10000 }
        ],
        [
          { bread: 100, point: 40 },
          { bread: 1000, point: 400 },
          { bread: 5000, point: 2000 },
        ]
      ]
    },
    selectTransfer: function (idx) {
      this.selectedTransferIdx = idx
    },
    onTransfer: function () {
      if (this.category == 0) {
        if (this.myInfo.points < this.transferList[this.category][this.selectedTransferIdx].point) {
          showToast(this.$t('common.point_alert'));
          return
        }
        this.transferPointToCash(
          this.selectedTransferIdx,
          Number(this.transferList[this.category][this.selectedTransferIdx].point),
          Number(this.transferList[this.category][this.selectedTransferIdx].bread))
      } else {
        if (this.myInfo.cash < this.transferList[this.category][this.selectedTransferIdx].bread) {
          showToast(this.$t('common.bread_alert'));
          return
        }
        this.transferCashToPoint(this.selectedTransferIdx)
      }

      //todo: setMyInfo api
    },

    transferCashToPoint: (selectedTransferIdx) => {
      const myInfo = store.getters.me;

      requestPost(
        'iap/transferCash',
        {
          uid: myInfo.uid,
          transfer_item_id: selectedTransferIdx,
        },
        async response => {
          myInfo.points = response.point;
          myInfo.cash = response.cash;

          await store.dispatch('setMyInfo', myInfo);
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    transferPointToCash: (selectedTransferIdx, selectedItemPoint, selectedItemCash) => {
      const myInfo = store.getters.me;

      requestPost(
        'iap/transferPointToCash',
        {
          uid: myInfo.uid,
          item_id: selectedTransferIdx,
          point: selectedItemPoint,
          cash: selectedItemCash
        },
        async response => {
          myInfo.points = response.point;
          myInfo.cash = response.cash;

          await store.dispatch('setMyInfo', myInfo);
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  },
}
</script>

